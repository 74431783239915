import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import style from './PriceCard.module.scss';
import Button from '../Button';

export type PriceCardProps = {
	primaryColor: string;
	secondaryColor: string;
	name: string;
	linkToDocs?: string;
	viaContact?: boolean;
	title: string;
	className?: string;
	ribbonText?: string;
	actionButton?: {
		label: string;
		disabled?: boolean;
		onClick: () => void;
	};
};

export default function PriceCard({
																		primaryColor,
																		secondaryColor,
																		linkToDocs,
																		ribbonText,
																		name,
																		title,
																		viaContact,
																		className,
																		children,
																		actionButton
																	}: PropsWithChildren<PriceCardProps>) {
	const classNames = cx(style.card, style.plan, className);

	return (
		<div
			className={classNames}
			style={{
				// @ts-ignore
				'--primary-color': primaryColor,
				'--secondary-color': secondaryColor
			}}
			data-testid="price-card"
		>
			{ribbonText && (
				<div
					className={style.ribbon}
					style={{
						borderRightColor: primaryColor,
						borderTopColor: primaryColor
					}}
				>
					<span>{ribbonText}</span>
				</div>
			)}
			<div className={style.title}>
				<h2>{title}</h2>
			</div>
			<div className={style.features}>{children}</div>
			<div className={style.actionButton}>
				{actionButton && !viaContact && (
					<Button
						color="secondary"
						disabled={actionButton.disabled}
						onClick={actionButton.onClick}
					>
						{actionButton.label}
					</Button>
				)}
				{viaContact && (
					<Button
						color="secondary"
						onClick={() => {
							window.location.href = 'mailto:anjali@collab.land';
						}}
					>
						Contact us for pricing
					</Button>
				)}
			</div>
			{linkToDocs && (
				<div className={style.learnMoreLink}>
					<a className={style.link} href={linkToDocs}>
						Learn more
					</a>
				</div>
			)}
		</div>
	);
}
