import { FaCheck, FaXmark } from 'react-icons/fa6';
import React from 'react';

export const Table = () => {
	return <div className="relative text-xs md:text-base bg-neutral-700 overflow-x-auto shadow-md sm:rounded-lg">
			<table className="w-full text-sm text-left rtl:text-right  text-white">
				<thead className="text-xs md:text-base 0 uppercase text-white">
				<tr className={'text-xs md:text-base'}>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 font-extrabold  bg-neutral-600">
						Tier Comparison
					</th>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 text-black font-bold bg-[#92ECB8]">
						Starter
					</th>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 text-black font-bold bg-[#FFC3FF]">
						Basic
					</th>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 text-black font-bold  bg-[#F6C349]">
						Premium
					</th>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 text-black font-bold bg-[#A0CDFF]">
						Exclusive
					</th>
					<th scope="col" className="md:px-6 md:py-3 px-1 py-1 text-black font-bold bg-[#9B9EFF]">
						Enterprise
					</th>
				</tr>
				</thead>
				<tbody>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						TGRs (Token Gating Rules)
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Up to 4
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						Up to 10
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Up to 50
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						Up to 150
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Unlimited
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Multi-wallet verification
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Access to 35+ blockchains
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Access to 25+ wallets, Delegate, OpenSea + WalletConnect
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				{/*<tr className={'text-xs md:text-base'}>*/}
				{/*	<th scope="row"*/}
				{/*			className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">*/}
				{/*		Standard Support*/}
				{/*	</th>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*</tr>*/}
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Priority Support
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Opt-out feature for donate
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Opt-out feature for community messages
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				{/*<tr className={'text-xs md:text-base'}>*/}
				{/*	<th scope="row"*/}
				{/*			className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">*/}
				{/*		Real-time balance checks for EVM chains*/}
				{/*	</th>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaXmark size={20} className={'text-neutral-500'} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">*/}
				{/*		<FaXmark size={20} className={'text-neutral-500'} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*</tr>*/}
				{/*<tr className={'text-xs md:text-base'}>*/}
				{/*	<th scope="row"*/}
				{/*			className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600 underline cursor-pointer"
							*/}
				{/*			onClick={() => {*/}
				{/*				window.open('https://docs.collab.land/help-docs/key-features/collab-tipping')*/}
				{/*			}}>*/}
				{/*		Community Tipping*/}
				{/*	</th>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaXmark size={20} className={'text-neutral-500'} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*	<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">*/}
				{/*		<FaCheck color={'#2ea303'} size={20} />*/}
				{/*	</td>*/}
				{/*</tr>*/}
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						PRO miniapp bundle
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Balance checks frequency
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Every 7 days
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						Every 24h
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Every 24h
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						Every 24h
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Every 24h
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Bonus “admin-initiated” balance checks
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						Up to 5/mo
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Upon Request
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Customize the Verification "Let’s Go!" Message
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Dedicated support human
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						White label (your own custom bot)
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Personalized features on demand
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						<FaXmark size={20} className={'text-neutral-500'} />
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						<FaCheck color={'#2ea303'} size={20} />
					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">

					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">

					</td>
					<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">

					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">

					</td>
					<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">

					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">

					</td>
				</tr>
				<tr className={'text-xs md:text-base'}>
					<th scope="row"
							className="md:px-6 md:py-2 px-1 py-1 font-medium  md:whitespace-nowrap  text-white bg-neutral-600">
						Monthly Price
					</th>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Free
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1  bg-neutral-600">
						$17.99
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						$35
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1 bg-neutral-600">
						$149
					</td>
					<td className="md:px-6 md:py-2 px-1 py-1">
						Contact us
					</td>
				</tr>
				</tbody>
			</table>
	</div>;
};